@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    font-family: "Roboto", "San Francisco", "Helvetica Neue", sans-serif;
    font-weight: 300;
    font-size: 14px;
    min-height: 100vh;
    background-image: url("pipes.png");
}

#app {
    min-height: 100vh;
}

/** dagre */
svg {
    font: 300 16px "KaTeX_Main", "Helvetica Neue", Helvetica;
    color: #333;
}

.node rect,
.node circle,
.node ellipse,
.node polygon {
    stroke: #333;
    fill: #fff;
    stroke-width: 1.5px;
}

.clusters rect {
    fill: #999;
    stroke: #333;
    stroke-width: 1.5px;
}

.edgePath path {
    stroke: #333;
    fill: #333;
    stroke-width: 1.5px;
}
